<template>
    <div class="flex flex-wrap justify-between items-center mb-2 py-3">
        <router-link class="btn btn-primary w-40 inline-block mr-1" :to="{ name: 'offerCreate' }">
            Создать
        </router-link>
    </div>

    <ContentTable
        :loading="loading"
        :headers="tableExample"
        :table-rows="tableData"
        :table-actions="tableActions"
        :meta="pagination"
        @edit="editAction"
        @delete="deleteAction"
    />

    <PopupDeleteModal
        :is-open="modalOpen"
        :entity-name="modalTitle"
        :modal-data="modalData"
        @update:isOpen="modalOpen = $event"
        @deleteModalAccepted="onDeleteModalAccepted"
    />
</template>
<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentTable from '@/components/tables/ContentTable';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'SpecialOffers',
    components: { ContentTable, PopupDeleteModal },
    mixins: [errorResponse, pageMixin],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchOffers({ ...to.query });
        next();
    },
    data() {
        return {
            loading: false,
            modalOpen: false,
            tableExample: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название' },
                { field: 'description', label: 'Описание' },
                { field: 'color', label: 'Цвет' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
            ],
            addButtonDelete: false,
            tableData: [],
            pagination: null,
            modalTitle: null,
            modalData: null,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Спецпредложения');
        await this.fetchOffers(this.$route.query);
    },
    methods: {
        async fetchOffers(params) {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/offers', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch offers: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalOpen = true;
        },
        editAction(row) {
            this.modalType = 'update';
            // this.$router.push({ name: 'offerEdit', params: { id: row.id, tab: 'offer' } });
            this._pMTransitionToEditPageWithQuery('offerEdit', { id: row.id, tab: 'offer' });
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/offers/' + data.id)
                .then(() => {
                    this.fetchOffers(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>
